import React from 'react'
import SEO from "../components/atoms/seo"

import Layout from '../components/organisms/layout'
import Heading from '../components/molecules/heading'
// import BodyCopy from '../components/atoms/body-copy'

import content from "../data/pages/questionnaire/content.json"

const ThankYouPage = () => (
  <Layout hideSecureForm>
    <SEO title='Thank You' />
    <div className='p-thank-you'>
      <Heading 
          title='¡Gracias!'
          subTitle='Te contactaremos pronto'
          weight='light'
          color='light'
      />
    </div>
  </Layout>
)

export default ThankYouPage
